<template>
  <div class="loader">
    <span class="bar"></span>
    <span class="bar"></span>
    <span class="bar"></span>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped lang="sass">
.loader
  display: flex
  align-items: center

.bar
  display: inline-block
  width: 3px
  height: 20px
  background-color: #fa07
  border-radius: 10px
  animation: scale-up4 1s linear infinite

.bar:nth-child(2)
  height: 35px
  margin: 0 5px
  animation-delay: .25s

.bar:nth-child(3)
  animation-delay: .5s

@keyframes scale-up4
  20%
    background-color: #fa0f
    transform: scaleY(1.5)

  40%
    transform: scaleY(1)
</style>
